import { useAuth } from "@context/authentication";
import { mapAppType } from "@helpers/mapper";
import {
  Source,
  WebEngageClickEvent,
  WebEngagePageView,
  WebEngageTracking,
  WebEngageUserAttributes,
  WebEngageUserEventAttributes,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import React, { createContext, useEffect, useState } from "react";

export const WebEngageContext = createContext({} as WebEngageTracking);

const DEFAULT_WEBENGAGE_USER_EVENT_ATTRIBUTES = {
  type: WebEngageUserEventType.Default,
};

type ModifiableUserProfileAttributes = {
  name: string;
  gender: string;
  dob: string;
  incomeRangeIdx: number;
  occupationIdx: number;
  countryCode: string;
};

const WEBENGAGE_GENDER_MAP = {
  1: "female",
  2: "male",
  3: "other",
};

const USER_PROFILE_UPDATE_ATTRIBUTES_MAP = {
  name: WebEngageUserAttributes.fullName,
  gender: WebEngageUserAttributes.gender,
  dob: WebEngageUserAttributes.dob,
  incomeRangeIdx: WebEngageUserAttributes.incomeRange,
  occupationIdx: WebEngageUserAttributes.occupation,
  countryCode: WebEngageUserAttributes.nationality,
};

const handleUserProfileUpdate = (
  currentProfile: ModifiableUserProfileAttributes,
  newProfile: Profile
) => {
  Object.entries(currentProfile).forEach((entry) => {
    const [attribute, value] = entry;

    if (
      Object.keys(USER_PROFILE_UPDATE_ATTRIBUTES_MAP).includes(attribute) &&
      value !== newProfile[attribute]
    ) {
      window.webengage.user.setAttribute(
        USER_PROFILE_UPDATE_ATTRIBUTES_MAP[attribute],
        newProfile[attribute]
      );
    }
  });
};

interface WebEngageProviderProps {
  children: React.ReactNode;
  siteConfig: SiteConfig;
}

const WebEngageProvider: React.FC<WebEngageProviderProps> = ({
  children,
  siteConfig,
}) => {
  const { user, isAuthenticating } = useAuth();
  const [userEventAttributes, setUserEventAttributes] = useState<
    WebEngageUserEventAttributes
  >(DEFAULT_WEBENGAGE_USER_EVENT_ATTRIBUTES);

  const [
    modifiableUserProfileAttributes,
    setModifiableUserProfileAttributes,
  ] = useState<ModifiableUserProfileAttributes>(null);

  useEffect(() => {
    const profile = user?.profile;

    if (profile && modifiableUserProfileAttributes) {
      handleUserProfileUpdate(modifiableUserProfileAttributes, {
        ...profile,
        gender: WEBENGAGE_GENDER_MAP[profile.gender] || null,
      });

      setModifiableUserProfileAttributes({
        name: profile.name,
        gender: WEBENGAGE_GENDER_MAP[profile.gender] || null,
        dob: profile.dob,
        incomeRangeIdx: profile.incomeRangeIdx,
        occupationIdx: profile.occupationIdx,
        countryCode: profile.countryCode,
      });
    }

    if (profile && !modifiableUserProfileAttributes) {
      setModifiableUserProfileAttributes({
        name: profile.name,
        gender: WEBENGAGE_GENDER_MAP[profile.gender] || null,
        dob: profile.dob,
        incomeRangeIdx: profile.incomeRangeIdx,
        occupationIdx: profile.occupationIdx,
        countryCode: profile.countryCode,
      });
    }
  }, [user?.profile]);

  useEffect(() => {
    const webEngage = window.webengage || {};
    if (!isAuthenticating && user) {
      if (
        userEventAttributes.type === WebEngageUserEventType.Login ||
        userEventAttributes.type === WebEngageUserEventType.Signup
      ) {
        webEngage.user.login(user.userHash);
        const {
          email,
          name,
          gender,
          dob,
          incomeRangeIdx,
          occupationIdx,
          countryCode,
        } = user.profile;
        const referralCode = user.referral.code;
        const userCreatedAt = new Date(user.createdAt);
        const wegoUserHash = user.wegoUserHash;
        const profileGender = WEBENGAGE_GENDER_MAP[gender] || null;

        email &&
          webEngage.user.setAttribute(WebEngageUserAttributes.email, email);

        if (name) {
          webEngage.user.setAttribute(WebEngageUserAttributes.fullName, name);
          webEngage.user.setAttribute(WebEngageUserAttributes.firstName, name);
        }

        profileGender &&
          webEngage.user.setAttribute(
            WebEngageUserAttributes.gender,
            profileGender
          ); // male | female | other

        dob && webEngage.user.setAttribute(WebEngageUserAttributes.dob, dob); // format is "1986-08-19"

        incomeRangeIdx &&
          webEngage.user.setAttribute(
            WebEngageUserAttributes.incomeRange,
            incomeRangeIdx
          );
        occupationIdx &&
          webEngage.user.setAttribute(
            WebEngageUserAttributes.occupation,
            occupationIdx
          );
        countryCode &&
          webEngage.user.setAttribute(
            WebEngageUserAttributes.nationality,
            countryCode
          );

        webEngage.user.setAttribute(
          WebEngageUserAttributes.referralCode,
          referralCode
        );
        webEngage.user.setAttribute(
          WebEngageUserAttributes.userCreatedAt,
          userCreatedAt
        ); // Format is 2020-11-19T04:27:29
        webEngage.user.setAttribute(
          WebEngageUserAttributes.emailNotificationsEnabled,
          user.preferences.emailNotificationsEnabled
        );

        wegoUserHash &&
          webEngage.user.setAttribute(
            WebEngageUserAttributes.wegoUserHash,
            wegoUserHash
          );

        if (userEventAttributes.type === WebEngageUserEventType.Login)
          window.webengage.track(WebEngageUserEventType.Login, {
            Source: Source.shopcash,
            Mode: userEventAttributes.mode,
          });

        if (userEventAttributes.type === WebEngageUserEventType.Signup)
          window.webengage.track(WebEngageUserEventType.Signup, {
            Source: Source.shopcash,
            Mode: userEventAttributes.mode,
          });

        setUserEventAttributes(DEFAULT_WEBENGAGE_USER_EVENT_ATTRIBUTES);
      }
    }

    if (userEventAttributes.type === WebEngageUserEventType.Logout) {
      webEngage.user.logout();

      setUserEventAttributes(DEFAULT_WEBENGAGE_USER_EVENT_ATTRIBUTES);
    }
  }, [userEventAttributes, isAuthenticating, user]);

  useEffect(() => {
    if (siteConfig) {
      const webEngage = window.webengage || {};
      const currencyCode = siteConfig.currencyCode;
      webEngage.user.setAttribute(
        WebEngageUserAttributes.siteCode,
        siteConfig.siteCode
      );
      webEngage.user.setAttribute(
        WebEngageUserAttributes.appType,
        mapAppType(siteConfig.appType)
      );
      currencyCode &&
        webEngage.user.setAttribute(
          WebEngageUserAttributes.currency,
          currencyCode
        );
    }
  }, []);

  useEffect(() => {
    const locale = siteConfig?.locale;
    locale &&
      window.webengage.user.setAttribute(
        WebEngageUserAttributes.language,
        locale
      );
  }, [siteConfig?.locale]);

  const handlePageViewTrack = (
    eventName: WebEngagePageView,
    eventData = {}
  ) => {
    window.webengage.track(eventName, eventData);
  };

  const handleEventTrack = (eventName: WebEngageClickEvent, eventData) => {
    window.webengage.track(eventName, eventData);
  };

  const handleUserEventTrack = (
    userEventAttributes: WebEngageUserEventAttributes
  ) => {
    if (
      [
        WebEngageUserEventType.Login,
        WebEngageUserEventType.Logout,
        WebEngageUserEventType.Signup,
      ].includes(userEventAttributes.type)
    ) {
      setUserEventAttributes(userEventAttributes);
    }
  };

  return (
    <WebEngageContext.Provider
      value={{
        trackWebEngagePageView: handlePageViewTrack,
        trackWebEngageClickEvent: handleEventTrack,
        trackWebEngageUserEvent: handleUserEventTrack,
      }}
    >
      {children}
    </WebEngageContext.Provider>
  );
};

export default WebEngageProvider;
